<template>
  <div
    class="d-flex flex-column justify-content-center align-items-start font-weight-bolder"
  >
    <div class="d-flex flex-column align-items-center">
      <span>
        <slot name="countryNum"
          ><span class="selected-items"
            >Selected countries: {{ selectedCountryNumber }} |
          </span>
        </slot>
        <slot name="channelNum"
          ><span class="selected-items"
            >Selected channels: {{ selectedChannelNumber }}</span
          >
          <br />
          <p class="d-flex justify-content-center">
            <small v-if="activeChannelCount > 0" class="small-text">
              <span
                v-b-tooltip.hover="{ variant: 'info', placement: 'bottom' }"
                title="Currently saved channels"
                >Active channels: {{ activeChannelCount }}</span
              >
              <span
                v-b-tooltip.hover="{ variant: 'info', placement: 'bottom' }"
                title="Channels you bought"
                v-if="subscribedChannelCount"
              >
                | Subscribed channels: {{ subscribedChannelCount }}</span
              >
            </small>
          </p>
        </slot>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  computed: {
    selectedCountryNumber() {
      return (
        this.$store.getters["channelSelector/geSelectedCountryIds"]?.length ?? 0
      );
    },
    activeChannelCount() {
      return (
        this.$store.getters["channelSelector/getActiveChannelIds"]?.length ?? 0
      );
    },
    selectedChannelNumber() {
      return (
        this.$store.getters["channelSelector/geSelectedChannelIds"]?.length ?? 0
      );
    },
    subscribedChannelCount() {
      const authUser = this.$store.getters["auth/authUser"];
      return (
        authUser.services?.find(({ code }) => code === "channel")?.quantity ?? 0
      );
    },
  },
};
</script>
<style scoped>
.selected-items {
  font-size: 12px;
}
.small-text {
  font-size: 11px;
}
@media (min-width: 768px) {
  .selected-items {
    font-size: 1rem;
  }
  .small-text {
    font-size: 12px;
  }
}
</style>
