<template>
  <b-row>
    <b-col
      v-for="(item, index) in source.items"
      :key="index"
      class="pb-1"
      :cols="cols"
    >
      <checkbox-list-item
        v-if="item && item.id"
        :source="item"
        :info-component="infoComponent"
        :channel-logo="channelLogo"
        :on-change="onChange"
        :get-class="getClass"
        :get-label="getLabel"
        :get-small-label="getSmallLabel"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import CheckboxListItem from "@/layouts/components/CheckboxListItem.vue";

export default {
  name: "CheckboxListRowItem",

  components: {
    CheckboxListItem,
    BRow,
    BCol,
  },
  props: {
    infoComponent: {
      type: Object,
      required: false,
      default: null,
    },
    channelLogo: {
      type: Boolean,
      default: false,
      required: false,
    },
    columns: {
      type: Number,
      default() {
        return 1;
      },
    },
    source: {
      type: Object,
      default() {
        return { index: 0, items: [] };
      },
    },
    onChange: {
      type: Function,
      required: false,
    },
    getClass: {
      type: Function,
      required: false,
    },
    getLabel: {
      type: Function,
      required: false,
    },
    getSmallLabel: {
      type: Function,
      required: false,
    },
  },
  computed: {
    cols() {
      return 12 / (this.source.items?.length ?? 1);
    },
  },
};
</script>

<style scoped></style>
