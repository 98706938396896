<template>
  <virtual-list
    wrap-class="flex row pb-5 px-1"
    v-if="groupedItem.length > 0"
    style="overflow-y: auto; overflow-x: hidden"
    data-key="index"
    :keeps="keep"
    :data-sources="groupedItem"
    :data-component="itemComponent"
    item-class="w-100"
    :extra-props="{
      onChange,
      getLabel,
      getSmallLabel,
      infoComponent,
      channelLogo,
      getClass,
      columns,
    }"
  />
</template>

<script>
import VirtualList from "vue-virtual-scroll-list";
import CheckboxListRowItem from "@/layouts/components/CheckboxListRowItem";
//CheckboxListRowItem for grid bug in virtual list component https://github.com/tangbc/vue-virtual-scroll-list/issues/289

const ITEM_SIZE = 60;
export default {
  name: "CheckboxList",
  components: {
    VirtualList,
  },
  props: {
    items: Array,
    getLabel: {
      type: Function,
      required: false,
    },
    getSmallLabel: {
      type: Function,
      required: false,
    },
    infoComponent: {
      type: Object,
      required: false,
    },
    channelLogo: {
      type: Boolean,
      default: false,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    getClass: {
      type: Function,
      required: false,
    },
    col: {
      type: [Number, String],
      required: false,
    },
    smCol: {
      type: [Number, String],
      required: false,
    },
    mdCol: {
      type: [Number, String],
      required: false,
    },
    lgCol: {
      type: [Number, String],
      required: false,
    },
    xlCol: {
      type: [Number, String],
      required: false,
    },
  },
  data() {
    return {
      cols: {
        sm: this.smCol ?? this.mdCol ?? this.lgCol ?? this.xlCol ?? 12,
        md: this.mdCol ?? this.lgCol ?? this.xlCol ?? 12,
        lg: this.lgCol ?? this.xlCol ?? 12,
        xl: this.xlCol ?? 12,
      },
      itemComponent: CheckboxListRowItem,
    };
  },
  computed: {
    keep() {
      const keep = Math.round(this.$screen.height / ITEM_SIZE) * 2;
      return keep;
    },
    columns() {
      const col = this.cols[this.$screen.breakpoint] ?? 12;
      return 12 / col;
    },
    groupedItem() {
      let groupedItem = [];
      let row = [];
      this.items.forEach((item) => {
        if (row.length === this.columns) {
          groupedItem.push({ index: groupedItem.length, items: row });
          row = [];
        }
        row.push(item);
      });
      if (row.length <= this.columns) {
        row = [...row, ...new Array(this.columns - row.length).fill({})];
        groupedItem.push({ index: groupedItem.length, items: row });
      }
      return groupedItem;
    },
  },
  methods: {
    onChange(checked, item) {
      return this.$emit("change", { checked, item });
    },
  },
};
</script>
<style>
.custom-checkbox .custom-control-label {
  display: flex;
  white-space: pre-line !important;
  cursor: pointer;
}
</style>
