<template>
  <div v-if="isAuthorized">
    <button class="btn btn-outline-success" @click.prevent="showModal">
      EPG
    </button>

    <b-modal
      ref="program-modal"
      centered
      :title="`Currently running on ${source.name}`"
      ok-only
      dialog-class="info-modal m-auto"
      content-class="w-auto m-auto"
    >
      <ChannelProgram :channel-id="source.id" />
    </b-modal>
  </div>
</template>

<script>
import ChannelProgram from "@/layouts/components/ChannelProgram";
import { BModal } from "bootstrap-vue";

export default {
  name: "ProgramInfoButton",
  components: {
    ChannelProgram,
    BModal,
  },
  props: {
    source: {
      type: Object,
    },
  },
  methods: {
    showModal(event) {
      if (!this.isAuthorized) return;
      event.stopPropagation();
      this.$refs["program-modal"].show();
    },
  },
  computed: {
    isAuthorized() {
      return !!this.$store.getters["auth/authUser"];
    },
  },
};
</script>

<style>
@media (min-width: 994px) {
  .info-modal {
    min-width: 700px;
    max-width: 1000px;
  }
}
</style>
