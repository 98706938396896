<template>
  <span />
</template>

<script>
export default {
  name: "SeverMessageToaster",
  mounted() {
    if (this.$route.query?.server_message) {
      this.$toast.info(this.$route.query?.server_message);
    }
  },
};
</script>

<style scoped></style>
