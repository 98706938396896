<template>
  <div
    class="d-flex justify-content-center align-items-center border rounded padding cursor-pointer"
    @click="$emit('click', $event)"
  >
    <div style="flex: 1; overflow: hidden">
      <slot name="label" />
    </div>
    <div
      class="d-flex justify-content-center align-items-center text-uppercase"
    >
      <slot name="rightContent"></slot>
      <slot name="info"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListItem",
};
</script>

<style lang="scss">
.padding {
  padding: 10px 15px;
}
</style>
