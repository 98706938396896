<template>
  <div class="h-100 bg-white border pt-1 pb-1 country-list">
    <home-title>Country list:</home-title>
    <b-form-checkbox v-model="checked" class="ml-1 mt-2"
      >Show only selected</b-form-checkbox
    >
    <country-list
      :countries="countries"
      :has-search="true"
      container-class="select-countries"
      @change="onChangeCountrySelector"
      :loading="loading"
    ></country-list>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
import { mapActions } from "vuex";
import HomeTitle from "@/layouts/components/home/HomeTitle";
import CountryList from "@/layouts/components/coutry/CountryList";
import { sortArray } from "@/services/utils/util";
import { fetchChannels } from "@/services/api/channels";

export default {
  data() {
    return {
      checked: false,
    };
  },
  components: {
    CountryList,
    BFormCheckbox,
    HomeTitle,
  },
  computed: {
    loading() {
      return this.$store.getters["channelSelector/getCountryLoading"];
    },
    selectedChannelIds() {
      return this.$store.getters["channelSelector/geSelectedChannelIds"];
    },
    allCountries() {
      return this.$store.getters["channelSelector/getCountryList"];
    },
    selectedCountries() {
      return this.$store.getters["channelSelector/geSelectedCountries"] ?? [];
    },
    countries() {
      const countries = this.checked
        ? this.selectedCountries
        : this.allCountries;
      return sortArray(countries, "name").reverse();
    },
  },
  methods: {
    ...mapActions({
      addCountry: "channelSelector/addCountry",
      removeCountry: "channelSelector/removeCountry",
    }),
    async onChangeCountrySelector({ checked, country }) {
      if (checked) {
        await this.addCountry(country);
        return false;
      }

      const {
        data: { data: channels = [] },
      } = await fetchChannels({ all: true, country_id: country.id });

      const hasSelectedChannelInThisCountry = channels.some(({ id }) =>
        this.selectedChannelIds.includes(id)
      );
      if (hasSelectedChannelInThisCountry) {
        this.$swal({
          title: "Delete this country?",
          text: `Would you like to delete all channels from ${country?.name}?`,
          type: "warning",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete them",
        }).then((result) => {
          result.value && this.removeCountry({ country, channels });
        });
      } else {
        await this.removeCountry({ country, channels });
      }

      return false;
    },
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 576px) {
  .country-list {
    min-height: 600px;
  }
}
@media (min-width: 992px) {
  .country-list {
    min-height: 780px;
  }
}

.max-container {
  height: 15vh;
}

.inputs {
  width: 75vw;
}

@media (min-width: 576px) {
  .inputs {
    width: 35vw;
  }
}

@media (min-width: 768px) {
  .max-container {
    height: 20vh;
  }
  .inputs {
    width: 30vw;
  }
}

@media (min-width: 992px) {
  .max-container {
    height: 25vh;
  }
  .inputs {
    width: 15vw;
  }
}
.select-countries {
  max-height: 90%;
}
</style>
