<template>
  <div class="d-flex">
    <h3 class="font-weight-bold">
      <span v-if="!loading" class="price-container"
        >{{ totalPrice }} {{ currency }}
        <small v-if="!isUpgrade"> {{ intervalUnitLabel }}</small> </span
      ><br />
    </h3>
    <b-spinner
      v-if="loading"
      label="Loading..."
      variant="success"
      class="mr-1"
      type="grow"
    />
  </div>
</template>
<script>
import { BSpinner } from "bootstrap-vue";
import { YEAR_INTERVAL_UNIT } from "@/store/channel-selector";

export default {
  components: {
    BSpinner,
  },
  computed: {
    currency() {
      const cartInfo = this.$store.getters["channelSelector/getCartInfo"];
      return cartInfo?.cartInfo?.currency?.name ?? "EUR";
    },
    isUpgrade() {
      return this.$store.getters["channelSelector/getIsUpgrade"];
    },
    totalPrice() {
      return this.$store.getters["channelSelector/getUpgradeInfo"]?.total ?? 0;
    },
    intervalUnitMultiplier() {
      return this.$store.getters["channelSelector/getIntervalUnit"] ===
        YEAR_INTERVAL_UNIT
        ? 12
        : 1;
    },
    intervalUnitLabel() {
      return this.$store.getters["channelSelector/getIntervalUnit"] ===
        YEAR_INTERVAL_UNIT
        ? "annually"
        : "month";
    },
    loading() {
      return this.$store.getters["channelSelector/getSyncLoading"];
    },
  },
};
</script>
<style scoped>
.dark-layout .h4 {
  color: #d0d2d6;
}
.price-container {
  font-size: 16px;
}
@media (min-width: 768px) {
  .price-container {
    font-size: 22px;
  }
}
</style>
