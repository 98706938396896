<template>
  <div>
    <user-epg-link
      v-if="authUser && authUser.services.length > 0 && authUser.epg_link"
      :epg-link="authUser.epg_link"
      class="w-100 mb-1"
    />
    <div
      class="bg-white border-bottom px-1 mb-lg-2 px-lg-3 py-lg-2 pay-relative"
      style="display: flex; flex-wrap: wrap"
    >
      <div class="col-xs-12 col-sm-8 p-0">
        <div class="d-flex justify-content-center">
          <h3 v-if="totalPrice > 0" class="font-weight-bold">
            {{ priceLabel }}:
          </h3>
          <price-tab-price v-if="totalPrice > 0" />
        </div>
        <div class="d-flex flex-column align-items-center">
          <price-tab-text />
          {{ loading ? "Loading" : "" }}
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 p-1">
        <div
          class="d-flex justify-content-center justify-content-sm-end flex-wrap"
        >
          <b-button
            class="btn btn-success btn-xs-block waves-effect waves-float waves-light mb-1"
            v-if="trialAllowed && !hasNoPendingSelectedChannel"
            @click="saveChannels(true)"
            :disabled="loading"
          >
            Start trial
          </b-button>
          <b-button
            v-if="!hasNoPendingSelectedChannel"
            class="btn btn-success btn-xs-block waves-effect waves-float waves-light mb-1"
            :disabled="loading"
            @click="saveChannels()"
          >
            {{ saveButtonText }}
            <b-spinner v-if="loading" type="grow" small label="Loading..." />
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BSpinner } from "bootstrap-vue";
import PriceTabPrice from "./PriceTabPrice.vue";
import PriceTabText from "./PriceTabText.vue";
import { mapActions } from "vuex";
import store from "@/store";
import UserEpgLink from "@/layouts/components/UserEpgLink.vue";

export default {
  components: {
    BButton,
    PriceTabPrice,
    PriceTabText,
    BSpinner,
    UserEpgLink,
  },
  computed: {
    authUser() {
      return store.getters["auth/authUser"];
    },
    loading() {
      return this.$store.getters["channelSelector/getSyncLoading"];
    },
    priceLabel() {
      return this.isUpgrade ? "Upgrade fee" : "Subscription fee";
    },
    isUpgrade() {
      return this.$store.getters["channelSelector/getIsUpgrade"];
    },
    totalPrice() {
      return this.$store.getters["channelSelector/getUpgradeInfo"]?.total ?? 0;
    },
    hasNoPendingSelectedChannel() {
      const user = store.getters["auth/authUser"];
      if (user.services.length > 0) {
        return (
          this.$store.getters["channelSelector/getDeletedChannelIds"].length ===
            0 &&
          this.$store.getters["channelSelector/getNewChannelIds"].length === 0
        );
      }

      return (
        this.$store.getters["channelSelector/geSelectedChannelIds"].length === 0
      );
    },
    activeChannelCount() {
      return this.$store.getters["channelSelector/getActiveChannelIds"].length;
    },
    saveButtonText() {
      if (!this.authUser) return "Login to order";
      return this.totalPrice > 0
        ? this.activeChannelCount > 0
          ? "Upgrade"
          : "Order"
        : "Save";
    },
    trialAllowed() {
      return store.getters["channelSelector/getCartInfo"]?.cartInfo
        ?.isTrialAllowed;
    },
    selectedChannelIds() {
      return this.$store.getters["channelSelector/geSelectedChannelIds"];
    },
    activeChannelIds() {
      return this.$store.getters["channelSelector/getActiveChannelIds"];
    },

    deletedChannelIds() {
      return this.$store.getters["channelSelector/getDeletedChannelIds"];
    },
  },
  methods: {
    ...mapActions({
      saveChannelModifications: "channelSelector/saveChannelModifications",
      revertDeletedChannels: "channelSelector/revertDeletedChannels",
      addChannel: "channelSelector/addChannel",
      removeChannel: "channelSelector/removeChannel",
      fetchCart: "channelSelector/fetchCart",
    }),
    saveChannels(trial = undefined) {
      if (!this.authUser) {
        this.$toast.info("You need to log in to save these channels");
        this.$router.push({ name: "login" });
      } else {
        if (this.deletedChannelIds.length > 0) {
          this.$swal({
            title: `Delete ${
              this.deletedChannelIds.length === 1
                ? "this channel?"
                : "these channels?"
            }`,
            text: `Would you like to delete ${this.deletedChannelIds.length} channel(s) from your subscription?`,
            type: "warning",
            icon: "warning",
            showCancelButton: true,
            showDenyButton: true,
            denyButtonColor: "#00cfe8",
            denyButtonText: "Keep and upgrade",
            confirmButtonColor: "#4BB543",
            confirmButtonText: `Yes, delete ${
              this.deletedChannelIds.length === 1 ? "it" : "them"
            }`,
          }).then(async (result) => {
            if (result.isDismissed) return;
            if (result.isDenied) {
              await this.revertDeletedChannels(trial);
            }
            await this.saveChannelModifications(trial);
          });
        } else {
          this.saveChannelModifications(trial);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  font-size: 34px;
}
h3 {
  font-size: 18px;
  margin-top: auto;
  margin-right: 0.6rem;
}
@media (min-width: 768px) {
  h1 {
    font-size: 44px;
  }
  h3 {
    font-size: 22px;
    margin-right: 1rem;
  }
}
@media (min-width: 992px) {
  h1 {
    font-size: 48px;
  }
}
</style>
