<template>
  <ListItem :class="`${itemClass} h-100`" @click.prevent="onChangeCheckbox">
    <template #label>
      <b-form-checkbox
        :checked="selected"
        style="flex-1"
        class="checkbox-multi-line"
      >
        <channel-logo
          v-if="channelLogo"
          img-style="width:25px; height:25px; margin-right: 10px;"
          :tvg-id="tvgId"
        />
        <span
          ><b
            :title="`${label} ${smallLabel}`"
            class="w-100 text-truncate d-flex flex-wrap"
          >
            <div>{{ label }}</div>
            <br />
          </b>
          <small v-if="smallLabel">{{ smallLabel }}</small>
        </span>
      </b-form-checkbox>
    </template>
    <template v-if="infoComponent" #info>
      <info-node class="info" :source="source" />
    </template>
  </ListItem>
</template>

<script>
import ListItem from "@/layouts/components/home/ListItem";
import { BFormCheckbox } from "bootstrap-vue";
import ChannelLogo from "@/layouts/components/ChannelLogo.vue";

export default {
  name: "CheckboxListItem",
  props: {
    listKey: String,
    index: {
      type: Number,
    },
    channelLogo: {
      type: Boolean,
      default: false,
      required: false,
    },
    infoComponent: {
      type: Object,
      required: false,
      default: null,
    },
    source: {
      type: Object,
      default() {
        return {};
      },
    },
    onChange: {
      type: Function,
      required: false,
    },
    getClass: {
      type: Function,
      required: false,
    },
    getLabel: {
      type: Function,
      required: false,
    },
    getSmallLabel: {
      type: Function,
      required: false,
    },
  },
  components: {
    ListItem,
    BFormCheckbox,
    ChannelLogo,
  },

  created() {
    this.$watch(
      () => this.infoComponent,
      () => (this.$options.components.InfoNode = this.infoComponent ?? null)
    );
  },
  beforeMount() {
    this.$options.components.InfoNode = this.infoComponent ?? null;
  },
  computed: {
    tvgId() {
      return this.source.tvg_id;
    },
    itemClass() {
      const className = this?.getClass?.(this.source) ?? "";
      return `mt-1 ${className}`;
    },
    label() {
      if (this.getLabel) {
        return this.getLabel(this.source);
      }
      return this.source.name ?? this.source.code;
    },
    smallLabel() {
      return this.getSmallLabel?.(this.source) ?? "";
    },
    selected() {
      return this.source.selected;
    },
  },
  methods: {
    onChangeCheckbox() {
      this.onChange(!this.source?.selected, this.source);
    },
  },
};
</script>

<style scoped>
.info {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
</style>
