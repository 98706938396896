var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.groupedItem.length > 0)?_c('virtual-list',{staticStyle:{"overflow-y":"auto","overflow-x":"hidden"},attrs:{"wrap-class":"flex row pb-5 px-1","data-key":"index","keeps":_vm.keep,"data-sources":_vm.groupedItem,"data-component":_vm.itemComponent,"item-class":"w-100","extra-props":{
    onChange: _vm.onChange,
    getLabel: _vm.getLabel,
    getSmallLabel: _vm.getSmallLabel,
    infoComponent: _vm.infoComponent,
    channelLogo: _vm.channelLogo,
    getClass: _vm.getClass,
    columns: _vm.columns,
  }}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }