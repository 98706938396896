<template>
  <div>
    <div class="text-center" v-if="!error && !program">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div id="flex-container" class="d-flex" v-if="!error && program">
      <div class="mr-1">
        <p><b>Title:</b> {{ title }} ({{ duration }}')</p>
        <p v-if="episodeNum">{{ episodeNum }}</p>
        <p>
          {{ $dateHelpers.formatDate(start) }} -
          {{ $dateHelpers.formatTime(stop) }}
        </p>
        <div class="progress" style="height: 4px; margin-bottom: 14px">
          <div
            class="progress-bar"
            role="progressbar"
            :style="`width: ${percent}%`"
            :aria-valuenow="percent"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <div class="img-container">
          <img
            v-if="logo"
            @error="onImageLoadError()"
            style="width: 100%; height: 100%; object-fit: contain"
            :src="imageSrc"
            alt="logo"
          />
          <img
            v-if="!logo"
            style="width: 100%; height: 100%; object-fit: contain"
            :src="imageSrc"
            alt="logo"
          />
        </div>
        <b-badge
          v-for="category in categories"
          :key="category"
          class="badge"
          variant="success"
          >{{ category }}</b-badge
        >
      </div>
      <div class="ml-1">
        <p id="desc" class="text-justify" v-if="desc">
          <b>Description:</b> {{ desc.slice(0, 200)
          }}<span v-if="moreDescription">{{ desc.slice(200) }}</span>
          <span
            v-if="desc.length > 200"
            @click="toggleMore()"
            style="color: #28c76f; cursor: pointer"
            >{{ lessMore }}</span
          >
        </p>
        <p v-if="originalTitle"><b>Original title:</b> {{ originalTitle }}</p>
        <p v-if="subTitle"><b>Subtitle:</b> {{ subTitle }}</p>
        <p v-if="country"><b>Country:</b> {{ country }}</p>
        <p v-if="producer"><b>Producer:</b> {{ producer }}</p>
        <p v-if="director"><b>Director:</b> {{ director }}</p>
        <p v-if="actor"><b>Cast:</b> {{ actor }}</p>
        <p v-if="writer"><b>Writers:</b> {{ writer }}</p>
        <p v-if="releaseDate"><b>Release date:</b> {{ releaseDate }}</p>
        <p v-if="starRating">
          <b>Star rating:</b> {{ starRating }}
          <feather-icon class="text-warning" icon="StarIcon" size="20" />
        </p>
        <p v-if="rating"><b>Rating: </b>{{ rating }}</p>
      </div>
    </div>
    <p v-if="error">{{ error }}</p>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { isString } from "@vueuse/shared";
import { BSpinner, BBadge } from "bootstrap-vue";
import xmlLogo from "../../assets/images/logo/logo.svg";

export default {
  name: "ChannelProgram",
  props: {
    channelId: Number,
  },
  components: {
    BSpinner,
    BBadge,
  },
  data() {
    return {
      logoError: false,
      moreDescription: false,
      lessMore: "...MORE",
    };
  },
  created() {
    this.$watch(
      () => this.logo,
      () => (this.logoError = false)
    );
  },
  computed: {
    program() {
      return this.$store.getters["actualProgram/getActualProgramData"];
    },
    error() {
      return this.$store.getters["actualProgram/error"];
    },
    title() {
      return isString(this.program?.details?.title)
        ? this.program?.details?.title
        : this.program?.details?.title[0];
    },
    start() {
      return this.program?.details?.["@attributes"]?.start;
    },
    stop() {
      return this.program?.details?.["@attributes"]?.stop;
    },
    desc() {
      return this.program?.details?.desc?.length > 0
        ? this.program?.details?.desc
        : null;
    },
    logo() {
      return this.program?.details?.icon?.["@attributes"]?.src ?? null;
    },
    duration() {
      const duration = (this.program?.stop_utc - this.program?.start_utc) / 60;
      return duration;
    },
    percent() {
      const diff =
        (Math.round(Date.now() / 1000) - this.program?.start_utc) / 60;
      if (diff > this.duration) {
        return 100;
      }
      if (diff < 1) {
        return 0;
      }
      return Math.round((diff / this.duration) * 100);
    },
    subTitle() {
      return this.program?.details?.["sub-title"] ?? null;
    },
    originalTitle() {
      return this.program?.details?.["original-title"] ?? null;
    },
    categories() {
      return isString(this.program?.details?.category)
        ? [this.program?.details?.category]
        : this.program?.details?.category;
    },
    country() {
      return isString(this.program?.details?.country)
        ? this.program?.details?.country
        : this.program?.details?.country?.join(" , ");
    },
    episodeNum() {
      const episodeNum = this.program?.details?.["episode-num"];
      if (!episodeNum) return null;
      const [season, episode] = episodeNum.split(".");
      return (
        (isNaN(parseInt(season)) ? `` : `Season ${parseInt(season) + 1}`) +
        (season && episode ? " | " : "") +
        (isNaN(parseInt(episode)) ? `` : `Episode ${parseInt(episode) + 1}`)
      );
    },
    producer() {
      if (this.program?.details?.credits?.producer) {
        return isString(this.program?.details?.credits?.producer)
          ? this.program?.details?.credits?.producer
          : this.program?.details?.credits?.producer.join(" , ");
      } else {
        return null;
      }
    },
    director() {
      if (this.program?.details?.credits?.director) {
        return isString(this.program?.details?.credits?.director)
          ? this.program?.details?.credits?.director
          : this.program?.details?.credits?.director.join(" , ");
      } else {
        return null;
      }
    },
    actor() {
      if (this.program?.details?.credits?.actor) {
        return isString(this.program?.details?.credits?.actor)
          ? this.program?.details?.credits?.actor
          : this.program?.details?.credits?.actor.join(" , ");
      } else {
        return null;
      }
    },
    writer() {
      if (this.program?.details?.credits?.writer) {
        return isString(this.program?.details?.credits?.writer)
          ? this.program?.details?.credits?.writer
          : this.program?.details?.credits?.writer.join(" , ");
      } else {
        return null;
      }
    },
    starRating() {
      return this.program?.details?.["star-rating"]?.value ?? null;
    },
    rating() {
      return this.program?.details?.rating?.value ?? null;
    },
    releaseDate() {
      return this.program?.details?.date ?? null;
    },
    imageSrc() {
      if (this.logo) {
        return this.logoError ? xmlLogo : this.logo;
      }
      return xmlLogo;
    },
  },
  methods: {
    ...mapActions({
      getPrograms: "actualProgram/getPrograms",
    }),
    onImageLoadError() {
      this.logoError = true;
    },
    toggleMore() {
      this.moreDescription = !this.moreDescription;
      if (this.moreDescription) {
        this.lessMore = " LESS";
      } else {
        this.lessMore = "...MORE";
      }
    },
  },
  mounted() {
    return this.getPrograms(this.channelId);
  },
};
</script>

<style scoped>
.img-container {
  width: 360px;
  height: 240px;
  margin: auto;
}
@media (max-width: 993px) {
  #flex-container {
    flex-direction: column;
  }
  #desc {
    margin-top: 14px;
  }
}
@media (max-width: 578px) {
  .img-container {
    width: 200px;
    height: 150px;
  }
}
@media (max-width: 350px) {
  .img-container {
    width: 150px;
    height: 120px;
  }
}
.badge {
  margin: 14px 5px 0;
}
</style>
