import { render, staticRenderFns } from "./PriceTabPrice.vue?vue&type=template&id=f0186dd4&scoped=true&"
import script from "./PriceTabPrice.vue?vue&type=script&lang=js&"
export * from "./PriceTabPrice.vue?vue&type=script&lang=js&"
import style0 from "./PriceTabPrice.vue?vue&type=style&index=0&id=f0186dd4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0186dd4",
  null
  
)

export default component.exports