<template>
  <div>
    <b-tabs
      class="d-block d-sm-none content-height"
      content-class="h-100"
      end
      justified
      @activate-tab="scrollToTop"
    >
      <b-tab
        title="Countries"
        class="h-100"
        :active="activeTab === 'countries'"
      >
        <keep-alive>
          <div class="h-100" style="display: flex; flex-flow: column">
            <country-picker-container />
          </div>
        </keep-alive>
      </b-tab>
      <b-tab title="Channels" class="h-100" :active="activeTab === 'channels'">
        <keep-alive>
          <div
            class="h-100"
            style="display: flex; flex-flow: column; overflow-y: auto"
          >
            <channels-picker-container />
          </div>
        </keep-alive>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTab, BTabs } from "bootstrap-vue";
import CountryPickerContainer from "./CountryPickerContainer.vue";
import ChannelsPickerContainer from "./ChannelsPickerContainer.vue";

export default {
  components: {
    BTabs,
    BTab,
    CountryPickerContainer,
    ChannelsPickerContainer,
  },
  data() {
    return {
      activeTab: "countries",
    };
  },
  methods: {
    onChangeTab(activeTab) {
      this.activeTab = activeTab;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters["app/currentBreakPoint"];
    },
  },
};
</script>
<style scoped>
.content-height {
  height: calc(100vh - 20rem);
  min-height: 80rem;
  margin-bottom: 5rem;
}
</style>
