<template>
  <h2 class="text-center font-weight-bold mb-1">
    <slot />
  </h2>
</template>
<script>
export default {
  name: "HomeTitle",
};
</script>
<style scoped></style>
