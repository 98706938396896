<template>
  <b-form-group class="my-1">
    <b-form-input
      type="search"
      id="basicInput"
      :placeholder="placeholder"
      @input="onChange"
    ></b-form-input>
  </b-form-group>
</template>
<script>
import { BFormGroup, BFormInput } from "bootstrap-vue";

export default {
  name: "SearchInput",
  components: {
    BFormGroup,
    BFormInput,
  },
  props: ["placeholder"],
  methods: {
    onChange(value) {
      this.$emit("input", value);
    },
  },
};
</script>
<style scoped></style>
