<template>
  <div :class="`p-1 channels-checkbox-list-container ${containerClass}`">
    <search-input
      v-if="hasSearch"
      class="inputs"
      placeholder="Search..."
      @input="onCountrySearchChanged"
    />
    <checkbox-list
      v-if="!loading"
      :items="_countries"
      @change="onChange"
      :loading="loading"
      :get-label="getLabel"
      lg-col="12"
    />
    <div
      v-if="loading"
      class="flex"
      style="overflow-y: auto; overflow-x: hidden"
    >
      <skeleton-list-loading :item-number="6" />
    </div>
  </div>
</template>

<script>
import SearchInput from "@/layouts/components/home/SearchInput";
import CheckboxList from "@/layouts/components/CheckboxList";
import SkeletonListLoading from "@/layouts/components/loading/SkeletonListLoading";

export default {
  name: "CountryList",
  components: {
    SkeletonListLoading,
    CheckboxList,
    SearchInput,
  },
  props: {
    title: String,
    countries: Array,
    hasSearch: Boolean,
    containerClass: String,
    loading: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      countrySearch: "",
    };
  },
  computed: {
    selectedCountryIds() {
      return this.$store.getters["channelSelector/geSelectedCountryIds"];
    },
    _countries() {
      const setSelectedAttribute = (countries) =>
        countries.map((country) => {
          return {
            ...country,
            selected: this.selectedCountryIds.includes(country.id),
          };
        });
      if (!this.hasSearch || !this.countrySearch) {
        return setSelectedAttribute(this.countries);
      }
      return setSelectedAttribute(
        this.countries?.filter(({ name = "" }) => {
          return name?.toLowerCase().includes(this.countrySearch.toLowerCase());
        })
      );
    },
  },
  methods: {
    onCountrySearchChanged(value) {
      this.countrySearch = value;
    },
    getLabel(country) {
      const suffix = country?.channel_count
        ? `[${country?.channel_count}]`
        : "";
      return ` (${country?.code?.toUpperCase()}) ${
        country.name ?? ""
      } ${suffix}`;
    },
    onChange({ checked, item: country }) {
      return this.$emit("change", { checked, country });
    },
  },
};
</script>
<style scoped>
.channels-checkbox-list-container {
  display: flex;
  flex-flow: column;
}
</style>
