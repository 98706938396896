<template>
  <div>
    <div
      v-for="index in itemNumber || 5"
      class="row"
      v-bind:key="index"
      style="height: 5rem; margin: 0.25rem"
    >
      <div class="col-12 w-100">
        <vue-skeleton-loader
          type="rect"
          animation="fade"
          width="100%"
          height="5rem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
  name: "SkeletonListLoading",
  components: { VueSkeletonLoader },
  props: {
    itemNumber: {
      type: Number,
      required: false,
    },
  },
};
</script>

<style scoped></style>
