<template>
  <div class="home-page">
    <sever-message-toaster />
    <portal to="pricePortal">
      <div>
        <price-tab-container />
      </div>
    </portal>
    <portal-target class="d-lg-none" name="pricePortal" />
    <b-row class="channel-selector-container d-none d-sm-flex my-md-1">
      <b-col class="h-100 col-6 px-sm-0 px-lg-1 px-md-0 col-lg-4">
        <country-picker-container />
      </b-col>
      <b-col
        class="h-100 col-6 px-sm-0 px-lg-1 px-md-1 col-lg-8"
        style="display: flex; flex-flow: column"
      >
        <portal-target
          class="d-none d-lg-block d-xl-block"
          name="pricePortal"
        />
        <div class="channel-picker-container">
          <channels-picker-container />
        </div>
      </b-col>
    </b-row>
    <tabs-wrapper />
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import TabsWrapper from "@/layouts/components/home/TabsWrapper.vue";
import PriceTabContainer from "@/layouts/components/priceTab/PriceTabContainer.vue";
import CountryPickerContainer from "@/layouts/components/home/CountryPickerContainer.vue";
import ChannelsPickerContainer from "@/layouts/components/home/ChannelsPickerContainer.vue";
import SeverMessageToaster from "@/layouts/components/SeverMessageToaster";

export default {
  components: {
    BRow,
    BCol,
    TabsWrapper,
    PriceTabContainer,
    CountryPickerContainer,
    ChannelsPickerContainer,
    SeverMessageToaster,
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters["app/currentBreakPoint"];
    },
    userData() {
      return this.$store.getters["auth/authUser"];
    },
  },
};
</script>

<style scoped lang="scss">
.channel-picker-container {
  height: 100%;
}
@media (min-width: 992px) {
  .channel-picker-container {
    height: calc(100% - 12rem);
  }
}
@media (min-width: 576px) {
  .home-page {
    min-height: 750px;
  }
  .channel-picker-container {
    min-height: 600px;
  }
}
@media (min-width: 992px) {
  .home-page {
    min-height: 785px;
  }
  .channel-picker-container {
    min-height: 575px;
  }
}
</style>
