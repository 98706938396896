<template>
  <div class="h-100 bg-white border pt-1 pb-1 channel-list">
    <div class="p-1 h-100" style="display: flex; flex-flow: column">
      <home-title>Channel list: </home-title>
      <div class="text-center mb-1" v-if="authUser">
        <feather-icon
          id="channel-info-icon"
          icon="InfoIcon"
          size="25"
          class="text-info"
        ></feather-icon>
        You can modify your subscription once every 24 hours.
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <b-form-group class="my-1">
            <v-select
              v-model="selectedCountry"
              label="name"
              :clearable="false"
              :options="selectedCountries"
              placeholder="No country is selected"
            />
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-6">
          <search-input v-model="channelSearch" placeholder="Search channels" />
        </div>
      </div>
      <div class="d-flex flex-wrap justify-content-between">
        <b-button
          class="btn waves-effect waves-float waves-light mb-1 channel-list-button"
          variant="outline-primary"
          :disabled="!selectedCountry"
          @click="massSelection"
        >
          {{
            selectedCountry &&
            selectedChannels.length === selectedCountry.channel_count
              ? "DESELECT ALL"
              : "SELECT ALL"
          }}
        </b-button>
        <b-button
          :disabled="!hasPendingChange || loading"
          class="btn btn-xs-block waves-effect waves-float waves-light mb-1 channel-list-button"
          variant="outline-success"
          @click="revertChanges"
        >
          Revert changes
        </b-button>
      </div>
      <b-form-checkbox v-model="showOnlySelectedChannels" class="m-1"
        >Show only selected</b-form-checkbox
      >
      <checkbox-list
        v-if="!loading"
        :items="showChannels"
        :get-label="getLabel"
        :get-small-label="getSmallLabel"
        :get-class="getChannelClass"
        :infoComponent="infoComponent"
        :channelLogo="channelLogo"
        @change="onChange"
        md-col="12"
        lg-col="12"
        xl-col="6"
        class="h-100"
      />
      <div
        v-if="loading"
        class="flex"
        style="overflow-y: auto; overflow-x: hidden"
      >
        <skeleton-list-loading :item-number="3" />
      </div>
    </div>
    <hr />
    <div
      v-if="loading"
      class="p-1 h-50"
      style="display: flex; flex-flow: column"
    >
      <div class="flex" style="overflow-y: auto; overflow-x: hidden">
        <skeleton-list-loading :item-number="3" />
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BFormGroup, BFormCheckbox } from "bootstrap-vue";
import vSelect from "vue-select";
import HomeTitle from "./HomeTitle.vue";
import { mapActions } from "vuex";
import CheckboxList from "@/layouts/components/CheckboxList";
import SearchInput from "@/layouts/components/home/SearchInput";
import SkeletonListLoading from "@/layouts/components/loading/SkeletonListLoading";
import ProgramInfoButton from "@/layouts/components/ProgramInfoButton";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    SkeletonListLoading,
    SearchInput,
    CheckboxList,
    BFormGroup,
    vSelect,
    BButton,
    HomeTitle,
    BFormCheckbox,
    FeatherIcon,
  },
  data() {
    return {
      channelLogo: true,
      infoComponent: ProgramInfoButton,
      checked: false,
      channelSearch: "",
      showOnlySelectedChannels: false,
    };
  },
  computed: {
    authUser() {
      return this.$store.getters["auth/authUser"];
    },

    loading() {
      return this.$store.getters["channelSelector/getChannelLoading"];
    },
    selectedCountry: {
      get() {
        return this.$store.getters["channelSelector/getSelectedCountry"];
      },
      set(country) {
        this.$store.dispatch("channelSelector/selectCountry", {
          countryId: country?.id,
        });
      },
    },
    selectedCountries() {
      return this.$store.getters["channelSelector/geSelectedCountries"];
    },
    selectedChannelIds() {
      return this.$store.getters["channelSelector/getSelectedCountry"];
    },
    channels() {
      return this.$store.getters["channelSelector/getSelectedCountryChannels"];
    },
    showChannels() {
      const showChannels = this.showOnlySelectedChannels
        ? this.selectedChannels
        : this.channels;
      if (!this.channelSearch) {
        return showChannels;
      }
      return showChannels?.filter(({ name = "" }) => {
        return name.toLowerCase().includes(this.channelSearch.toLowerCase());
      });
    },
    selectedChannels() {
      return this.$store.getters[
        "channelSelector/getSelectedCountryChannels"
      ].filter((channel) => channel.selected);
    },
    activeChannelIds() {
      return this.$store.getters["channelSelector/getActiveChannelIds"];
    },
    hasPendingChange() {
      return (
        this.$store.getters["channelSelector/getDeletedChannelIds"]?.length >
          0 ||
        this.$store.getters["channelSelector/getNewChannelIds"]?.length > 0
      );
    },
  },
  methods: {
    ...mapActions({
      addChannel: "channelSelector/addChannel",
      removeChannel: "channelSelector/removeChannel",
      selectAllChannelByCountry: "channelSelector/selectAllChannelByCountry",
      deselectAllChannelByCountry:
        "channelSelector/deselectAllChannelByCountry",
      revertChanges: "channelSelector/revertChanges",
    }),
    getLabel(channel) {
      return `${channel.name} ${"\n"}`;
    },
    getSmallLabel(channel) {
      return `(${channel.tvg_id})`;
    },
    getChannelClass(channel) {
      return (
        !channel?.selected &&
        this.activeChannelIds.includes(channel?.id) &&
        "element-border-warning"
      );
    },
    onChange({ checked, item: channel }) {
      const payload = { channelId: channel?.id };
      checked ? this.addChannel(payload) : this.removeChannel(payload);
    },
    massSelection() {
      this.selectedChannels.length === this.selectedCountry?.channel_count
        ? this.deselectAllChannelByCountry({
            countryId: this.selectedCountry?.id,
          })
        : this.selectAllChannelByCountry({
            countryId: this.selectedCountry?.id,
          });
    },
  },
};
</script>

<style lang="scss">
@media (min-width: 576px) {
  .channel-list {
    min-height: 100%;
  }
}
.channel-list-button {
  width: 40%;
}

@media (max-width: 900px) {
  .channel-list-button {
    width: 100%;
  }
}

.channel-list .max-container {
  height: 15vh;
}
.channel-list .inputs {
  width: 75vw;
}

@media (min-width: 576px) {
  .channel-list .inputs {
    width: 35vw;
  }
}

@media (min-width: 768px) {
  .channel-list .max-container {
    height: 20vh;
  }
  .channel-list .inputs {
    width: 30vw;
  }
}

@media (min-width: 992px) {
  .channel-list .max-container {
    height: 15vh;
  }
  .channel-list .inputs {
    width: 15vw;
  }
  .channel-list .modal-container {
    display: flex;
  }
  .channel-list .channels-container {
    width: 30%;
    height: 200px;
    border-right: 1px solid black;
  }
}
.vs__dropdown-option--selected {
  color: white !important;
}
</style>
