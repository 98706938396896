<template>
  <span @click="$emit('click', $event)" :class="wrapClass">
    <img
      v-if="logo && !failed"
      @error="onImageLoadError()"
      @loadstart="onImageLoadStart()"
      @loadeddata="onImageLoadFinished()"
      :style="imgStyle"
      :class="imgClass"
      :src="logo"
    />
    <span
      style="
        color: #28c76f;
        border: 1px solid green;
        border-radius: 4px;
        padding: 4px 8px;
      "
      v-if="defaultText && (!logo || loading || failed)"
    >
      {{ defaultText }}
    </span>
  </span>
</template>

<script>
export default {
  name: "ChannelLogo",
  props: {
    tvgId: {
      type: String,
      required: false,
    },
    defaultText: {
      type: String,
    },
    imgStyle: {
      required: false,
    },
    imgClass: {
      required: false,
    },
    wrapClass: {
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      failed: false,
    };
  },
  computed: {
    logo() {
      const countryCode = this.tvgId?.split(".")?.at(-1);
      if (!countryCode) {
        return undefined;
      }
      return `${process.env.VUE_APP_STATIC_URL}${countryCode}/${this.tvgId}.png`;
    },
  },
  methods: {
    onImageLoadError() {
      this.failed = true;
      this.loading = true;
    },
    onImageLoadStart() {
      this.failed = false;
      this.loading = true;
    },
    onImageLoadFinished() {
      this.failed = false;
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
